<template>
  <div class="mobile-menu-wrapper" ref="wrapperRef">
    <div id="mobile-menu" class="mobile-menu" style="background-color: rgba(0,0,0,0);">
      <div class="mobile-menu-closed" ref="closedMenuRef">
        <img src="@/assets/scanner-logo.png" style="height: 40px; cursor: pointer; z-index: 100001;" @click="navigateHome" />
        <h1 style="margin-left: 10px; cursor: pointer; font-size: 20px; z-index: 100001;" @click="navigateHome">{{$t("COMPANY_NAME")}}</h1>
        <generic-button icon="moile-menu" ref="buttonRef" simple dense :color="menuOpen ? '#2E293D' : '#2E293D'" label="" @on-click="toggleMenu" style="position: absolute; right: 0; top: 5px; z-index: 100001;" />
      </div>
      <div class="mobile-menu-open" ref="menuRef">
        <div class="mobile-menu-open-mid">
          <a v-for="item in menuItems" v-bind:key="item" v-bind="item" class="mobile-menu-button" style="pointer-events: all;" :href="item.href" @click="navigateHome" >{{$t(item.label)}}</a>
          <!--<generic-dropdown :items="$i18n.availableLocales" @value-change="value => $i18n.locale = value" style="width: 30vw;" />-->
        </div>
        <div class="mobile-menu-open-bottom">
          <store-badges :scale="0.8" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import clamp from "lodash.clamp";

import animations from "../../utils/animations";
import GenericButton from "../generic/GenericButton";
// import GenericDropdown from "@/components/generic/GenericDropdown";
import StoreBadges from "@/components/common/StoreBadges";
export default {
  name: "MobileMenu",
  components: {
    "store-badges": StoreBadges,
    "generic-button": GenericButton,
    // "generic-dropdown": GenericDropdown
  },
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const menuOpen = ref(false);
    const buttonRef = ref(null);
    const wrapperRef = ref(null);
    const closedMenuRef = ref(null);
    const router = useRouter();
    let lastScroll = 0;
    let menuTranslate = 0;
    function navigateHome() {
      if(menuOpen.value) {
        toggleMenu()
      }
      if(window.location.pathName !== "/") {
        router.push("/")
      }
    }

    const menuRef = ref(null);
    function disableMenu(e) {
      e.preventDefault()
    }
    function toggleMenu() {
      if(menuOpen.value) {
        buttonRef.value.$el.children[0].children[0].children[0].style.transform = "rotate(0deg)";
        buttonRef.value.$el.children[0].children[0].children[1].style.transform = "rotate(0deg)";
        buttonRef.value.$el.children[0].children[0].children[2].style.transform = "rotate(0deg)";

        document.getElementById("app").removeEventListener("wheel",disableMenu)
        document.getElementById("app").removeEventListener("mousewheel",disableMenu)
        document.getElementById("app").removeEventListener("touchmove",disableMenu)
        document.getElementById("app").removeEventListener("DOMMouseScroll",disableMenu)
        animations.slideRightLeave(menuRef.value)
        menuOpen.value = false
      } else {
        buttonRef.value.$el.children[0].children[0].children[0].style.transition = "all 0.7s";
        buttonRef.value.$el.children[0].children[0].children[0].style.transform = "rotate(45deg)";
        buttonRef.value.$el.children[0].children[0].children[0].style.transformOrigin = "85% 75%";

        buttonRef.value.$el.children[0].children[0].children[1].style.transition = "all 0.7s";
        buttonRef.value.$el.children[0].children[0].children[1].style.transform = "rotate(-45deg)";
        buttonRef.value.$el.children[0].children[0].children[1].style.transformOrigin = "50% 50%";


        buttonRef.value.$el.children[0].children[0].children[2].style.transition = "all 0.7s";
        buttonRef.value.$el.children[0].children[0].children[2].style.transform = "rotate(45deg)";
        buttonRef.value.$el.children[0].children[0].children[2].style.transformOrigin = "35% -35%";

        document.getElementById("app").addEventListener("wheel",disableMenu)
        document.getElementById("app").addEventListener("mousewheel",disableMenu)
        document.getElementById("app").addEventListener("touchmove",disableMenu)
        document.getElementById("app").addEventListener("DOMMouseScroll",disableMenu)
        animations.slideLeftEnter(menuRef.value)
        menuOpen.value = true
      }
    }

    const scrollListener = () => {
      const scrollTop = document.scrollingElement.scrollTop
      const menuHeight = closedMenuRef.value.clientHeight
      const oh = document.documentElement.style.getPropertyValue("--oh")
      const windowHeight = 100 * parseFloat(oh.substring(0, oh.length - 2))
      if(scrollTop < lastScroll) {
        menuTranslate = Math.min(scrollTop, clamp(menuTranslate + (scrollTop - lastScroll) / 3,0,menuHeight))
        wrapperRef.value.style.transform = `translateY(-${menuTranslate}px)`
      } else {
        menuTranslate = Math.min(scrollTop,clamp(menuTranslate - (lastScroll - scrollTop),0,menuHeight))
        wrapperRef.value.style.transform = `translateY(-${menuTranslate}px)`
      }
      if((window.location.pathname === "/" && scrollTop > windowHeight) || (window.location.pathname !== "/" && scrollTop > menuHeight)) {
        closedMenuRef.value.classList.add("fixed")
      } else {
        closedMenuRef.value.classList.remove("fixed")

      }
      lastScroll = scrollTop
    }

    onMounted(() => {
      document.addEventListener("scroll", scrollListener)
    })

    onUnmounted(() => {
      document.removeEventListener("scroll", scrollListener)
    })

    return {
      buttonRef,
      toggleMenu,
      menuRef,
      navigateHome,
      menuOpen,
      wrapperRef,
      closedMenuRef
    }
  }
}
</script>

<style scoped>
.mobile-menu-wrapper {
  width: 100vw;
  height: calc(var(--vh) * 100);
  position: fixed;
  z-index: 999999999;
  overflow-y: visible;
  pointer-events: none;
  will-change: transform;
}
.mobile-menu {
  width: 100vw;
  height: calc(var(--vh) * 100);
  overflow: hidden;
  position: relative;
}
.mobile-menu-closed {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  box-sizing: border-box;
  pointer-events: all;
}
.mobile-menu-closed.fixed {
  background-color: white;
}
.mobile-menu-open {
  width: 100vw;
  height: 100%;
  transform: translate(100%,0);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: url(../../assets/burger-menu-background.jpg);
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
}

.mobile-menu-open-mid {
  width: 100%;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  pointer-events: none;
}
.mobile-menu-button {
  font-weight: bold;
  font-size: 3vh;
  line-height: 234.67%;
  text-decoration: none;
  text-align: center;
  color: #2E293D;
  margin: 2vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-menu-open-bottom {
  width: 100%;
  padding: 20px;
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
</style>